import React from 'react';
import './Card.css';
import StarRateIcon from '@material-ui/icons/StarRate';
import firebase from 'firebase';


class AddData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        title: "",
        author: "",
        link: "",
        likes: 0,
        stack_title: "",
        user: "",
        userName: ""
    }
  
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)};
    async componentDidMount() {
        const _firebaseToken = await firebase.auth().currentUser?.getIdToken().then((token) => {
        this.setState({user: token})})
        const _username = await firebase.auth().currentUser.displayName
        this.setState({userName: _username })
    }

    handleChange(event) {
        const value = event.target.value;
        this.setState({
          [event.target.name]: value
        });
    }
    
    handleSubmit(event) {
    alert('A new stack was submitted: ' + this.state.title);
    event.preventDefault();
    let backendURL = "https://rvrdq7g6lf.execute-api.us-east-2.amazonaws.com/dev/"
    if (window.location.href.includes('localhost')) {  
      // if we are on a local environment, use the localhost URL  
      backendURL = 'http://localhost:4000/dev/'
    }
    console.log(this.state)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', "authorization": this.state.user},
        body: JSON.stringify(this.state)
    };
    let resData = fetch(backendURL + "add_to_stack", requestOptions)
        .then(response => response.json())
    console.log(resData);
    console.log("dsfihaoisfjaoisdjfa");
    }


  render() {
  return (
    <form onSubmit={this.handleSubmit} className="postForm">
        <div className="postLabelContainer">
        <label>
          Title:
          <input type="text" name="title" value={this.state.title} onChange={this.handleChange} className="titleInput"/>
        </label>
        </div>
        <div className="postLabelContainer">
        <label className="postLabel">
          Stack:
          <select name="stack_title" value={this.state.stack_title} onChange={this.handleChange} className="postDropdown">
            <option value="grapefruit">Machine Learning</option>
        </select>
        </label>
        </div>
        <div className="postLabelContainer">
        <label className="postLabel">
          Author:
          <input type="text" name="author" value={this.state.author} onChange={this.handleChange} className="titleInput"/>
        </label>
        </div>
        <div className="postLabelContainer">
        <label className="postLabel">
          Link:
          <input name="link" value={this.state.link} onChange={this.handleChange} className="titleInput"/>
        </label>
        </div>
        <input type="submit" value="Submit" className="postSubmitButton"/>
      </form>
  );
}
}

export { AddData };