import React from 'react';
import './App.css';
import { LandingPage } from './LandingPage.js'
import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyA3hGqoM9F2HxqV14Zif6umTYQNHv3Sv2U",
  authDomain: "stacks-social.firebaseapp.com",
  projectId: "stacks-social",
  storageBucket: "stacks-social.appspot.com",
  messagingSenderId: "785947206835",
  appId: "1:785947206835:web:6a75951b46a12886377cc6",
  measurementId: "G-MTRP50S0H5"
};

firebase.initializeApp({
  apiKey: "AIzaSyA3hGqoM9F2HxqV14Zif6umTYQNHv3Sv2U",
  authDomain: "stacks-social.firebaseapp.com",
  projectId: "stacks-social",
  storageBucket: "stacks-social.appspot.com",
  messagingSenderId: "785947206835",
  appId: "1:785947206835:web:6a75951b46a12886377cc6",
  measurementId: "G-MTRP50S0H5"
});

firebase.analytics();

function App() {
  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  return (
    <div className="app">
        <LandingPage/>
    </div>
  );
}

export default App;