import React, { Profiler } from 'react';
import './InitialPage.css';
import { CardData } from './Card.js'
import {Stack} from './Stack.js'

class InitialPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        cards: null,
        posting: false,
    };
    }
    

    render() {
    return (
        <div className="home">
                <div className="homeFeedOverall">
                    <div className="homeFeed">
                    <CardData/>
                    </div>
                    <div className="homeStacks">
                    <Stack/>
                    </div>
                </div>
        </div>
    );
  }
}
export {InitialPage}