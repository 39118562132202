import React from 'react';
import './Card.css';
import './Profile.css'
import StarRateIcon from '@material-ui/icons/StarRate';
import firebase from 'firebase';
import {CardData} from './Card.js'


class Profile extends React.Component {
  constructor(props) {
    super(props);
  this.state = {
      profile: [],
      _token: "",
      data: []
  };
  }

  async componentDidMount() {
      let backendURL = "https://rvrdq7g6lf.execute-api.us-east-2.amazonaws.com/dev/"
      if (window.location.href.includes('localhost')) {  
        // if we are on a local environment, use the localhost URL  
          backendURL = 'http://localhost:4000/dev/'
      }
      const _firebaseToken = await firebase.auth().currentUser?.getIdToken().then((token) => {
          this.setState({_token: token})
          })
      let userStacks = await fetch(backendURL + "profile", 
          {
            headers: {
              "authorization": this.state._token
            }
          }).then((response) => response.json());
       if (userStacks.status === 401) {
          return console.log('unauthorized') }
      console.log(userStacks)
      this.setState({
        data: userStacks.body
      });
        
  }
  

  render() {
    if (!this.state.data) {
      return null;
  }
  else{
  return (
    <div className="profileCards">
      <div className="profileInfo">
        <div className="profilePicture">
          Picture
        </div>
        <div className="profileInfoSubHolder">
            <div className="profileName">
              {firebase.auth().currentUser.displayName}
            </div>
            <div className="profileBio">
              This is my bio... 
            </div>
        </div>
      </div>
      <div className="profileCardHolder">
        <div className="yourCardHolder"> 
          Your Cards
          </div>
          <div>
          <CardData data={this.state.data}/>
          </div>
      </div>
    
  </div>

);
  }
}
}

export { Profile };