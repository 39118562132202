import React from 'react';
import './Card.css';
import StarRateIcon from '@material-ui/icons/StarRate';
import firebase from 'firebase';

class IndividualCard extends React.Component {
  constructor(props) {
    super(props);
  };

  render () {
    const expanded = this.props.expand
    const d = this.props.data
    if (expanded){
    return (
      <div className="expandedCard" key={d.post_id} onClick={() => this.props.handleCardClick(d.post_id)}>
        <div className="title">{d.title}</div>
        <div className="author">{d.author}</div>
        <div className="expandedMiddleBoxHolder">
          
        <div className="expandedMiddleBox">
        </div>
        </div>
        <div className="likes">
          <div className="likesContainer">
            <div className="likesNum">{d.likes}</div>
            <div className="likesIcon">
              <StarRateIcon/>
              </div>
              </div>
        </div>
        <div className="stackAddedTo">
          Added to {d.stack_title} by {d.user}
        </div>
        </div>
    );
}
else{
  return (
    <div className="card" key={d.post_id} onClick={() => this.props.handleCardClick(d.post_id)}>
        <div className="title">{d.title}</div>
        <div className="author">{d.author}</div>
        <div className="likes">
          <div className="likesContainer">
            <div className="likesNum">{d.likes}</div>
            <div className="likesIcon">
              <StarRateIcon/>
              </div>
              </div>
        </div>
        <div className="stackAddedTo">
          Added to {d.stack_title} by {d.user}
        </div>
        </div>
  );
}

}
}


class CardData extends React.Component {
  constructor(props) {
    super(props);

  this.state = {
      data: [],
      _token: "",
      expanded: null
  };
  this.handleCardClick = this.handleCardClick.bind(this)
};

  handleCardClick(post_id) {
    if (post_id == this.state.expanded){
      post_id = null;
    }
    this.setState({expanded: post_id});
  }


  async componentDidMount() {
    let backendURL = "https://rvrdq7g6lf.execute-api.us-east-2.amazonaws.com/dev/"
    if (window.location.href.includes('localhost')) {  
      // if we are on a local environment, use the localhost URL  
        backendURL = 'http://localhost:4000/dev/'
    }
    const _firebaseToken = await firebase.auth().currentUser?.getIdToken().then((token) => {
        this.setState({_token: token})
        })
    let seedData = await fetch(backendURL + "add_to_stack", 
        {
          headers: {
            "authorization": this.state._token
          }
        }).then((response) => response.json());
     if (seedData.status === 401) {
        return console.log('unauthorized') }
    seedData = seedData.body;
    this.setState({
      data: seedData
    });
  
  }

  render() { 
    console.log(this.props)
    if (!this.props.data){
 return (
  this.state.data.map((d) =>
  <div>
  {this.state.expanded != d.post_id && <IndividualCard handleCardClick={this.handleCardClick} expand={false} data={d}/>}
  {this.state.expanded === d.post_id && <IndividualCard handleCardClick={this.handleCardClick} expand={true} data={d}/>}
  </div>
  
  ));}
  else {
    return (
    this.props.data.map((d) =>
  <div>
  {this.state.expanded != d.post_id && <IndividualCard handleCardClick={this.handleCardClick} expand={false} data={d}/>}
  {this.state.expanded === d.post_id && <IndividualCard handleCardClick={this.handleCardClick} expand={true} data={d}/>}
  </div>
    ));
  }
 }
}


export { CardData, IndividualCard };