import React from 'react';
import './LandingPage.css';
import network from "./images/network.png"
import logo from "./images/logo.png"

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import {NavBar} from './NavBar.js'


  // Initialize Firebase


  
class LandingPage extends React.Component {
  state = {
    isSignedIn: false, // Local signed-in state.
    emailInput: "",
    passwordInput: "",
    posting: false
  };

  // Configure FirebaseUI.
  uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false
    }
  };
  
  async componentDidMount() {
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
        (user) => this.setState({isSignedIn: !!user})
    );
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }


  render() {
    if (!this.state.isSignedIn){
        return (<div className="page">
      <div className="top">
          <div className="border_bottom">
              <div className="logo">
              <img src={logo} className="logoImage"></img> 
              </div>
          </div>
      </div>
      <div className="middleHalf">
        <div className="title2">
            Stacks
        </div>
        <div className="description">
        Join a growing community of academics for the advent of social education
        </div>
      </div>
        <div className="auth_bucket">
            <StyledFirebaseAuth className="firebaseUI" uiConfig={this.uiConfig} firebaseAuth={firebase.auth()} class="auth"/>
        </div>
      <div className="bottom">
      </div>
  </div>
    );}
    else{
      let button;
        if (!this.state.posting){
            button = <div className="addPost">
                    <button onClick={this.handlePost} className="homePageButton"> Post </button>
                </div>
        }
        else{
            button = <div className="postContainer">
                Default
            </div>
        }
        return (
            <div className="signedInHome">
                <div className="signedInHeader">
                    <div className="signedInUser"> Signed in as {firebase.auth().currentUser.displayName}!</div>
                 <div  className="signOutButtonContainer">
                     <a onClick={() => firebase.auth().signOut()} className="signOutButton">Sign-out</a>
                 </div>
              </div>
              <div className="mainContainer">
                    <NavBar/>
        </div>               
            </div>
            
          );
    }

  }
}
  
export {LandingPage}