import React from 'react';
import './Stack.css';
import StarRateIcon from '@material-ui/icons/StarRate';

class Stack extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        title: "neuro-rl",
        description: "An open ended exploration of neuroscience and it’s relationship to reinforcement learning.",
        num_followers: 128,
    }
  };

  render () {
    return (
      <div className="stackHolder">
          <div className="stackTitle">
            {this.state.title}
          </div>
          <div className="stackDescription">
          {this.state.description}
          </div>
          <div className="stackFollowers">
              <div className="stackFollowersNum">
            {this.state.num_followers} 
            </div>
            <div className="stackFollowersText">
            Followers
            </div>
          </div>
          <div className="stackAuthor">
            @allanbishop
          </div>
          <div className="stackFollow">
              Follow
          </div>
      </div>
    );
}
}

export {Stack};