import React, { Profiler } from 'react';
import './NavBar.css';
import { Switch, Route, BrowserRouter as Router, Link } from 'react-router-dom';
import {Profile} from "./Profile.js";
import {InitialPage} from "./InitialPage.js"
import {AddData} from "./Add.js"
import styled from 'styled-components';


const StyledLink = styled(Link)`
    text-decoration: none;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`;

class NavBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        cards: null,
        posting: false,
    };
    this.handlePost = this.handlePost.bind(this)}

    handlePost(event) {
        event.preventDefault();
        this.setState({
            posting: !this.state.posting
        });
        }

    render() {
        let button;
        if (!this.state.posting){
            button = <div className="addPost">
                    <button onClick={this.handlePost} className="homePageButton"> Post </button>
                </div>
        }
        else{
            button = <div className="postContainer">
                <AddData/>
            </div>
        }
    return (
        <div className="mainContainer">
            <Router>
                <div className="navBar">
                <div className="options">
                    <div className="optionsText">
                        <ul>
                        <li><Link to="/" style={{ textDecoration: 'none', color:'white' }}>Home</Link></li>
                        <li>Discover</li>
                        <li><Link to="/profile" style={{ textDecoration: 'none', color:'white' }}>Profile</Link></li>
                    </ul>                   
                </div>
            </div>
            <div className="buttonForPost">
            {button}
            </div>
            </div>
                <div className="HomePageContainer">
                <Switch>
                <Route path="/profile" component={Profile}/>
                <Route path="/" component={InitialPage}/>
                </Switch>
            </div>
            </Router>
        </div>
    );
  }
}
export {NavBar}